html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  background: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Lato, Arial, Helvetica, sans-serif;
  line-height: 1.4em;
  color: #525f7f;
}

/* #root {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
} */

header {
  height: 70px;
  background: #fff;
  text-align: center;
}

main {
  height: calc(100% - 140px);
  background: #fff;
}

footer {
  height: 70px;
  background: #32a060;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

#bottom-menu {
  display: flex;
  list-style-type: none
}

#bottom-menu li a {
  color: #fff;
  padding: 0 10px;
  text-decoration: none;
  font-size: 14px;
}

.logo {
  width: 227px;
}

footer .logo {
  width: 175px;
}

.wrapper {
  height: calc(100% - 140px);
  width: 100%;
  display: flex;
  background-color: #fff;
  position: relative;
}

.innerLeft, .innerRight {
  flex: 1 1;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.innerLeft {
  background-color: #fff;
  justify-content: flex-end;
}

.innerRight {
  justify-content: flex-start;
  align-self: center;
}

iframe {
  margin-bottom: 32px !important;
}

@media screen and (max-width:425px) {
	.innerRight {
    display: none;
  }
  .innerLeft {
    align-items: flex-start;
    margin-top: 30px;
    height: auto;
    min-height: calc(100vh - 170px);
  }
  .innerLeft .content {
    padding: 0 30px;
    margin: 0 0 60px 0;
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
  footer {
    padding: 0 20px;
  }
  #bottom-menu li a {
    font-size: 10px;
  }
  footer .logo {
    display: none;
  }
  #root {
    height: auto;
    position: static;
  }
  
}

.content {
  margin: 30px 45px 30px 60px;
  max-width: 350px;
  min-width: 350px;
  justify-content: center;
}

.content img {
  max-width: 100%;
}

h1, h2 {
  font-weight: 900;
  font-size: 28px;
  margin-bottom: 1rem;
  margin-top: 4px;
  line-height: 1.2143;
  font-family: Lato, Arial, Helvetica, sans-serif;
  color: #1d1c1d;
  word-break: break-word;
}

.desc {
  font-size: 15px;
  line-height: 1.46668;
  color: #1d1c1d;
  margin-bottom: 1.5rem!important;
}

.small_desc {
  color: #616061;
  font-size: 13px;
  line-height: 1.38463;
  margin-top: 1.5rem!important;
}

.mb-2 {
  margin-bottom: 1.5rem!important;
}

.c-button {
  font-size: 18px;
  font-weight: 900;
  height: 44px;
  min-width: 96px;
  padding: 0 16px 3px;
  transition: all 80ms linear;
  background: #32a060;
  color: #fff;
  width: 100%!important;
  max-width: 100%!important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  align-items: center;
  position: relative;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.button {
  text-decoration: none;
}

.c-button:disabled {
  background: #ccc;
}

input[type=text], input[type=password], input[type=email] {
  height: 48px;
  border-radius: 4px;
  border: 1px solid #868686;
  transition: border 80ms ease-out,box-shadow 80ms ease-out;
  box-sizing: border-box;
  margin: 0 0 20px;
  padding: 3px 12px 5px;
  height: 36px;
  width: 100%;
  font-size: 15px;
  line-height: 1.86666667;
  line-height: normal;
}

label {
  font-weight: 700;
  margin: 0 0 .5rem;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
}

.price {
  margin: 70px 0 30px;
}

.error {
  color: red
}

.tenant-input {
  position: relative;
}

.tenant-input span {
  position: absolute;
  right: 9px;
  top: 7px;
}

.tenant-input input {
  padding-right: 135px;
}

.prevLink {
  margin-top: 20px;
  display: block;
}

.mainHeadline {
  text-align: center;
  margin-bottom: 0;
  color: #fff;
  font-weight: 400;
}
.stepper {
  text-align: center;
  display: block;
  color: #fff;
}

.headlinWrapper {
  background: #32a060;
  padding: 10px 0;
}

/* Loading Ring */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #32a060;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #32a060 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

